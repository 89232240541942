<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <b-row>
              <b-col md="6" class="text-left">
                <strong>Modulo de Venta - Ver</strong>
              </b-col>
              <b-col md="6" class="text-right">
                <strong>{{sale.reference }}</strong>
              </b-col>
            </b-row>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form">
              <b-row>
               <b-col md="2">
                  <b-form-group label="Comprobante :">
                    <b-form-select disabled v-model="sale.type_invoice" :options="type_invoice"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Serie :">
                    <b-form-input disabled v-model="sale.serie"></b-form-input>
                    <small v-if="errors.id_serie"  class="form-text text-danger" >Seleccione una serie</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Numero :">
                    <b-form-input class="text-center" disabled type="text" ref="number"  v-model="sale.number"></b-form-input>
                    <small v-if="errors.number" class="form-text text-danger">Ingrese un numero de 8 digitos</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha Emision:">
                    <b-form-input disabled class="text-center" type="date" ref="broadcast_date" v-model="sale.broadcast_date"></b-form-input>
                    <small v-if="errors.broadcast_date" class="form-text text-danger">Seleccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Moneda">
                    <b-form-select disabled @change="ChangeCoin" v-model="sale.coin" :options="coins"></b-form-select>
                    <small  v-if="errors.coin"  class="form-text text-danger">Seleccione una moneda</small>
                  </b-form-group>
                </b-col>

                 <b-col md="2">
                  <b-form-group>
                    <label class="control-label">Forma de Pago: <span v-if="disabled_fees_collected" class="badge badge-primary link" @click="ModalFeedCollected">Cuotas</span></label>
                    <b-form-select disabled @change="BntFeesCollected" v-model="sale.way_to_pay" :options="way_to_pay"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <label>Cliente: </label>
                    <v-select disabled @input="AddressClient" placeholder="Seleccione un cliente" class="w-100" :filterable="false" label="full_name" v-model="client" @search="SearchClients" :options="clients"></v-select>
                    <small v-if="errors.id_client" class="form-text text-danger" >Selccione un cliente</small>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Dirección :">
                    <b-form-input disabled type="text" ref="address"  v-model="sale.address"></b-form-input>
                    <small v-if="errors.address" class="form-text text-danger">Ingrese una dirección</small>
                  </b-form-group>
                </b-col>

                <!-- Detalle venta -->
                <b-col md="12">
                     <div class="w-100">
                        <div class="table-responsive mt-3">
                              <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                                <thead>
                                  <tr>
                                    <th width="3%" class="text-center">#</th>
                                    <th width="7%" class="text-center">Codigo</th>
                                    <th width="46%" class="text-center">Nombre</th>
                                    <th width="12%" class="text-center">UM</th>
                                    <th width="9%" class="text-center">Cantidad</th>
                                    <th width="10%" class="text-center">P. Unit</th>
                                    <th width="7%" class="text-center">P. Total</th>
                                  </tr>
                                </thead>
                                <tbody v-for="(item, it) in sale_detail" :key="it">
                                  <tr>
                                      <td class="align-middle text-center">{{ it + 1 }}</td>
                                      <td class="align-middle text-center">{{ item.code }}</td>
                                      <td class="align-middle text-left">{{ item.name }}</td>
                                      <td class="align-middle text-center">{{ NameUnitMeasure(item.unit_measure) }}</td>
                                      <td class="align-middle text-right">{{ item.quantity }}</td>
                                      <td class="align-middle text-right">{{ item.unit_price }}</td>
                                      <td class="align-middle text-right">{{ item.total_price }}</td>
                                  </tr>
                                </tbody>
                              </table>
                        </div>
                  </div>
                </b-col>

                <b-col md="12" class="mt-2"> <hr> </b-col>

                 <b-col md="3">
                   <div class="table-responsive">
                    <table  class="table  table-bordered table-hover table-lg mt-lg mb-0">
                      <thead>
                        <tr>
                          <th width="25%" class="text-center">Fecha</th>
                          <th width="65%" class="text-center">Refenrencia</th>
                          <th width="10%" class="text-center">Acc.</th>
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in sale.linkages" :key="it">
                        <tr>
                            <td class="align-middle text-center">{{ item.broadcast_date }}</td>
                            <td class="align-middle text-center">{{ item.reference }}</td>
                            <td class="align-middle text-center">
                              <button type="button" @click="DeleteLinkeage(it)" class="btn btn-danger"><i class="fas fa-trash-alt"></i></button>  
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Observación:">
                    <b-form-textarea disabled size="sm" v-model="sale.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>
               
                <b-col md="3"></b-col>
                <b-col md="3">
                  <div class="table-responsive">
                    <table  class="table   table-hover table-lg mt-lg mb-0">
                      <tbody>
                        <tr>
                            <td width="50%" class="align-middle text-right text-total">Subtotal:</td>
                            <td width="50%" class="align-middle text-right text-total">
                              <b-form-input readonly size="sm" type="number" step="any" class="text-right" v-model="sale.subtotal"></b-form-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle text-right text-total">Descuento:</td>
                            <td class="align-middle text-right text-total">
                              <b-form-input readonly size="sm" type="number" step="any" class="text-right" v-model="sale.discount"></b-form-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle text-right text-total">IGV:</td>
                            <td class="align-middle text-right text-total">
                              <b-form-input readonly size="sm" type="number" step="any" class="text-right" v-model="sale.igv"></b-form-input>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-middle text-right text-total">Total:</td>
                            <td class="align-middle text-right text-total">
                              <b-form-input readonly size="sm" type="number" step="any" class="text-right" v-model="sale.total"></b-form-input>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="align-middle text-center"><small  v-if="errors.total"  class="form-text text-danger">Ingrese un monto</small></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>

          

                <b-col md="12">
                  <br>
                </b-col>
                
              
                <b-col md="12">
                  <b-row>
                      <b-col md="4"></b-col>
                      <b-col md="2">
                        <b-form-group label="">
                          <b-button  type="button" @click="DataPrint" class="form-control text-white" variant="warning" > Imprimir</b-button>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group label="">
                          <b-link class="btn form-control btn-primary" :to="{ path: '/venta/listar' }" append>Regresar</b-link >
                        </b-form-group>
                      </b-col>
                  </b-row>
                </b-col>

              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

  
    <b-modal hide-title hide-footer ref="modal-confirm-sale">
      <b-form @submit.prevent="EditSale">
        
        <b-row>
          <b-col class="text-center" md="12">
              <div class="w-100"><i  class="fas fa-question-circle fa-5x"></i></div>
              <p class="my-4 h3">Esta seguro de modificar la venta?</p>
          </b-col>
          <b-col md="3"></b-col>
          <b-col md="6"><b-button size="lg" ref="buttonconfirmsale" type="submit" variant="primary" class="form-control">Si, Estoy de Acuerdo !</b-button></b-col>
        </b-row>
      </b-form>
    </b-modal>

  

    <ModalProducts />
    <ModalClients />
    <ModalOrders />
    <ModalCurrencyConverter />
    <ModalFeesCollected :fees_collected="sale.fees_collected" :way_to_pay="sale.way_to_pay" :broadcast_date="sale.broadcast_date"/>
    <LoadingComponent :is-visible="isLoading"/>
    <!-- <Keypress key-event="keyup" :key-code="113" @success="modalProducts" /> -->
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>
<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.text-total{
  font-size: 14px;
  font-weight: 500;
}


</style>

</style>
<script>
// importando paquetes
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");
import EventBus from '@/assets/js/EventBus';
import { mapState,mapActions } from "vuex";
import CodeToName from "@/assets/js/CodeToName";

// importando components
import ModalClients from './../components/ModalClient'
import ModalProducts from './components/ModalProduct'
import SaleDetail from './components/SaleDetail'
import ModalOrders from './components/ModalOrder'
import ModalFeesCollected from './components/ModalFeesCollected'
import LoadingComponent from './../pages/Loading'
import ModalCurrencyConverter from './../components/ModalCurrencyConverter'

export default {
  name: "SaleAdd",
  props: ["id_sale"],
  components:{
      vSelect,  
      ModalProducts,
      SaleDetail,
      ModalClients,
      ModalOrders,
      ModalFeesCollected,
      LoadingComponent,
      ModalCurrencyConverter,
      Keypress: () => import('vue-keypress'),
  },
  data() {
    return {
      quantity_vouchers:[
        {value:0, text:'No imprimir'},
        {value:1, text:'Imp. 1 Comprobante'},
        {value:2, text:'Imp. 2 Comprobantes'},
        {value:3, text:'Imp. 3 Comprobantes'},
      ],
      order: {
        room_name:'',
        table_name:'',
        table_name:'',
        code:'',
      },
      print_voucher: 1,
      isLoading: false,
      module: 'Sale',
      role: 3,
      search_barcode:'',
      document_type:'',
      sale: {
        id_sale: "",
        id_client: "",
        id_serie: "",
        reference: "",
        id_warehouse: "",
        type_operation: "01",
        linkages: [],
        type_invoice: "03",
        serie: "",
        number: "",
        broadcast_date: moment(new Date()).local().format("YYYY-MM-DD"),
        broadcast_time: "",
        expiration_date: moment(new Date()).local().format("YYYY-MM-DD"),
        web_pay: 0,
        coin: "PEN",
        address: '',
        code_sap: '',
        order_sap: '',
        service: '',
        check_contingency: "0",
        check_retention: "0",
        check_discount: "0",
        way_to_pay: "01-000",
        payment_type: "01",
        payment_method: "008",
        payment_deadline: "0",
        fees_collected:[],
        observation: "",
        license_plate: "",
        modified_document_type: "",
        modified_serie: "",
        modified_number: "",
        modified_emission_date: "",
        reason: "",
        support: "",
        sunat_message: "",
        hash_cpe: "",
        hash_cdr: "",
        taxed_operation: '0.00',
        exonerated_operation: '0.00',
        unaffected_operation: '0.00',
        retention: '0.00',
        discount: '0.00',
        subtotal: '0.00',
        igv: '0.00',
        total: '0.00',
        net_total: '0.00',
        state: '1',
        number_to_letters: '',
      },
      sale_detail:[],
      series: null,
      warehouses: [],
      clients: [],
      client: {id:1,full_name:'CLIENTES VARIOS - 00000000'},
      type_invoice:[
        {value: "01", text : "Factura"},
        {value: "03", text : "Boleta de Venta"},
        {value: "NV", text : "Nota de Venta"},
        {value: "07", text : "Nota de Crédito"},
        {value: "08", text : "Nota de Débito"},
      ],
      coins:[
        {value: "PEN", text : "Soles"},
        {value: "USD", text : "Dolares"},
      ],
      way_to_pay:[
        {value:"01-000", text :'Contado'},
        {value:'03-7',text:'Credito - 7 Dias'},
        {value:'03-15',text:'Credito - 15 Dias'},
        {value:'03-30',text:'Credito - 30 Dias'},
        {value:'03-45',text:'Credito - 45 Dias'},
        {value:'03-60',text:'Credito - 60 Dias'},
        {value:'03-75',text:'Credito - 75 Dias'},
        {value:'03-90',text:'Credito - 90 Dias'},
        {value:'03-105',text:'Credito - 105 Dias'},
        {value:'03-120',text:'Credito - 120 Dias'},
      ],

       payment_method: [
          {value :"001", text :'DEPÓSITO EN CUENTA'},
          {value :"003", text :'TRANSFERENCIA DE FONDOS'},
          {value :"004", text :'ORDEN DE PAGO'},
          {value :"005", text :'TARJETA DE DÉBITO'},
          {value :"006", text :'TARJETA DE CRÉDITO'},
          {value :"007", text :'CHEQUES CON LA CLÁUSULA DE "NO NEGOCIABLE"'},
          {value :"008", text :'EFECTIVO'},
          {value :"101", text :'TRANSFERENCIAS - COMERCIO EXTERIOR'},
          {value :"102", text :'CHEQUES BANCARIOS  - COMERCIO EXTERIOR'},
          {value :"333", text :'RETENCIÓN'},
          {value :"444", text :'DETRACCIÓN'},
          {value :"000", text :'PAGO POR WEB'},
      ],

      cashs:[],
      payment_cash : [],
      //errors
      errors: {
        id_serie: false,
        id_client: false,
        way_to_pay: false,
        address: false,
        sale_detail: false,
        total: false,
      },
      validate: false,

      ///cuotas 
      disabled_fees_collected: false,
    };
  },
  mounted() {
    
    
    EventBus.$on('InvoiceInformation', (data) => {
      this.client = data.client;
      this.sale.type_invoice = data.type_invoice;
      this.AddressClient();
    });

    EventBus.$on('ChangeFeesCollected', (data) => {
      this.sale.fees_collected = data;
    });


    this.mLoadResetSaleDetail();
    this.mLoadResetLinkages();
    this.ViewSale();
  },
  methods: {
    
    SearchClients,
    AddressClient,
    ViewSale,
    modalClients,
    EditSale,
    Validate,
    DataPrint,
    Print,

    modalConfirmSale,
    BntFeesCollected,
    ModalFeedCollected,
    DeleteLinkeage,

    ChangeCoin,
    NameUnitMeasure,
    ...mapActions('Sale',['mLoadResetSaleDetail','mLoadResetLinkages','mLoadDeleteLinkages','mLoadAddSaleDetail','mLoadEditCoin','mLoadTotalSaleDetail']),
  },

  computed: {
    ...mapState('Sale',['linkages']),
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    type_print: function () {
      let business = window.localStorage.getItem("business");
      business = JSON.parse(JSON.parse(je.decrypt(business)));
      return business.type_print;
    },

   
  },
};

function NameUnitMeasure(code) {
  return CodeToName.NameUnitMeasure(code);
}

function ViewOrder() {
  
  let me = this;
  let id_order = me.sale.id_order;
  let url = this.url_base + "order/view/"+id_order;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,module: this.module,role: me.role},
  })
    .then(function (response) {
      
      if (response.data.status == 200) {
        me.order.room_name = response.data.result.order.room_name;
        me.order.table_name = response.data.result.order.table_name;
        me.order.moso_name = response.data.result.order.moso_name;
        me.order.code = response.data.result.order.code;
      }
    })
    .catch((error) => {
      console.log(error)
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function ViewSale() {
  
  let id_sale = je.decrypt(this.id_sale);
  let me = this;
  let url = this.url_base + "sale/view/" + id_sale;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module,role: this.role},
  })
  .then(function (response) {
    if (response.data.status == 200) {
        me.client = {id: response.data.result.sale.id_client,full_name: response.data.result.sale.name + ' - ' + response.data.result.sale.document_number};

        me.sale.id_sale = response.data.result.sale.id_sale,
        me.sale.id_warehouse = response.data.result.sale.id_warehouse,
        me.sale.type_operation = response.data.result.sale.type_operation,
        me.sale.type_invoice = response.data.result.sale.type_invoice;
        me.sale.reference = response.data.result.sale.reference;
        me.sale.linkages = response.data.result.sale.linkages;
        me.sale.serie = response.data.result.sale.serie;
        me.sale.number = response.data.result.sale.number;
        me.sale.broadcast_date = response.data.result.sale.broadcast_date;
        me.sale.broadcast_time = response.data.result.sale.broadcast_time;
        me.sale.expiration_date = response.data.result.sale.expiration_date;
        me.sale.coin = response.data.result.sale.coin;
        me.sale.address = response.data.result.sale.address;
        if (response.data.result.sale.payment_type == "01") {
          me.sale.way_to_pay = response.data.result.sale.payment_type+'-'+response.data.result.sale.payment_method;
          me.disabled_fees_collected = false;
        }else{
          me.sale.way_to_pay = response.data.result.sale.payment_type+'-'+response.data.result.sale.payment_deadline;
          me.disabled_fees_collected = true;
        }
        me.sale.payment_type = response.data.result.sale.payment_type;
        me.sale.payment_method = response.data.result.sale.payment_method;
        me.sale.payment_deadline = response.data.result.sale.payment_deadline;
        me.sale.observation = response.data.result.sale.observation;
        me.sale.service = response.data.result.sale.service;
        me.sale.modified_document_type = response.data.result.sale.modified_document_type;
        me.sale.modified_serie = response.data.result.sale.modified_serie;
        me.sale.modified_number = response.data.result.sale.modified_number;
        me.sale.modified_emission_date = response.data.result.sale.modified_emission_date;
        me.sale.reason = response.data.result.sale.reason;
        me.sale.support = response.data.result.sale.support;
        me.sale.sunat_message = response.data.result.sale.sunat_message;
        me.sale.hash_cpe = response.data.result.sale.hash_cpe;
        me.sale.hash_cdr = response.data.result.sale.hash_cdr;
        me.sale.taxed_operation = response.data.result.sale.taxed_operation;
        me.sale.exonerated_operation = response.data.result.sale.exonerated_operation;
        me.sale.unaffected_operation = response.data.result.sale.unaffected_operation;
        me.sale.discount = response.data.result.sale.discount;
        me.sale.subtotal = response.data.result.sale.subtotal;
        me.sale.igv = response.data.result.sale.igv;
        me.sale.total = response.data.result.sale.total;
        me.sale.net_total = response.data.result.sale.net_total;
        me.sale.state = response.data.result.sale.state;
        me.sale.number_to_letters = response.data.result.sale.number_to_letters;
        me.sale.fees_collected = response.data.result.sale.fees_collected;
        ///agregar detalle pedido
        me.sale_detail = response.data.result.sale_detail;
      
    } else {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    }
  })

}

function ChangeCoin() {
  this.mLoadEditCoin(this.sale.coin);
}

function AddressClient() {
  if (this.client == null) {
    this.sale.address = '';
    return false;
  }
  let me = this;
  let url = this.url_base + "client/view/"+this.client.id;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: this.role, },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.sale.address = response.data.result.address;
      } 
    })
}

function SearchClients(search, loading) {
   let me = this;
    let url = this.url_base + "search-clients/" + search;
    if (search !== "") {
      loading(true);
      axios({
        method: "GET",
        url: url,
      }).then(function (response) {
            me.clients = response.data;
            loading(false);
      })
    }
}

function modalClients() {
  EventBus.$emit('ModalClientsShow');
}

function DeleteLinkeage(index) {
  this.mLoadDeleteLinkages(index);
}

function EditSale() {
  let me = this;
  me.isLoading = true;
  let url = me.url_base + "sale/edit";
  me.sale.id_user = me.user.id_user;
  me.sale.id_sale = me.sale.id_sale;
  me.sale.id_client = me.client.id;
  me.sale.taxed_operation = me.total_sale.taxed_operation;
  me.sale.unaffected_operation = me.total_sale.unaffected_operation;
  me.sale.exonerated_operation = me.total_sale.exonerated_operation;
  me.sale.discount = me.total_sale.discount;
  me.sale.subtotal = me.total_sale.subtotal;
  me.sale.igv = me.total_sale.igv;
  me.sale.total = me.total_sale.total;
  me.sale.net_total = me.total_sale.net_total;
  me.sale.number_to_letters = me.total_sale.number_to_letters;
  me.sale.linkages = me.linkages;
  me.sale.sale_detail = me.sale_detail;
  
  let data = me.sale;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json",token: me.token,module: me.module,role: me.role,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.$refs['modal-confirm-sale'].hide();
      me.isLoading = false;
    })
    .catch((error) => {
      me.isLoading = false;
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function Validate() {
  
  this.errors.broadcast_date = this.sale.broadcast_date.length == 0 ? true : false;
  this.errors.id_client = this.client == null ? true : false;
  this.errors.coin = this.sale.coin.length == 0 ? true : false;
  this.errors.way_to_pay = this.sale.way_to_pay.length == 0 ? true : false;
  this.errors.sale_detail = this.sale_detail.length == 0 ? true : false;
  this.errors.total = parseFloat(this.total_sale.total) <= 0 ? true : false;
  
  if (this.errors.broadcast_date == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.id_client == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.coin == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.way_to_pay == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.sale_detail == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }
  if (this.errors.total == true) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }


  
  let total = 0;
  let payment_method = this.sale.way_to_pay.split("-");
  if (payment_method[0] == "03") {
    if (this.sale.fees_collected.length > 0) {
      for (let index = 0; index < this.sale.fees_collected.length; index++) {
        const element = this.sale.fees_collected[index];
        if (element.date == "") {
          this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que las cuotas cuenten con un fecha', timer: 2000,}); return false;
        }
        if (element.total.length == 0) {
          this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que el total de las cuotas sean mayores a 0', timer: 2000,}); return false;
        }
        if (parseFloat(element.total) <= 0) {
          this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que el total de las cuotas  sean mayores a 0', timer: 2000,}); return false;
        }
        total += parseFloat(element.total);
      }
      let balance_fee_collection = parseFloat(this.total_sale.net_total) - parseFloat(total);
      if (balance_fee_collection < 0 || balance_fee_collection > 0.15) {
        this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que las cuotas coincidan con el total del comprobante', timer: 4000,}); 
        return false;
      }
    }
  }
  
  this.quantity_vouchers = [
    {value:0, text:'No imprimir'},
    {value:1, text:'Imp. 1 Comprobante'},
    {value:2, text:'Imp. 2 Comprobantes'},
    {value:3, text:'Imp. 3 Comprobantes'},
  ];
  
  this.modalConfirmSale();
}

function modalConfirmSale() {
  this.$refs['modal-confirm-sale'].show();
}

function DataPrint() {
    let me = this;
    let url = me.url_base + "sale/data-print/"+me.sale.id_sale;
    let data = me.sale;
    axios({
      method: "GET",
      url: url,
      data: data,
      headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role, },
    })
    .then(function (response) {
      if (response.data.status == 200) {
        me.Print(response.data.result);
      } 

    })
  
  // if (this.type_print == 2) {
  //   let url = this.url_base + "voucher-sale/"+id_sale;
  //   window.open(url,'_blank');
  // }
  
}

function Print(info) {
  let url = 'http://localhost/print/consumirapi.php';
  var data = new FormData(); 
  data.append("data",JSON.stringify(info)); 

  axios({
    method: "POST",
    url: url,
    data:data,
    headers: {
      "Content-Type": "application/json",
      "Accept":"*/*",
    },
  })
    .then(function (response) {
     
    })
    .catch((error) => {
      console.log(error);
    });
}

// CUOTAS DE PAGO
function BntFeesCollected() {
  let payment_type = this.sale.way_to_pay.split('-');
  if (payment_type[0] == "03") {
    this.disabled_fees_collected = true;
  }else{
    this.disabled_fees_collected = false;
    this.sale.fees_collected = [];
    
  }

  EventBus.$emit('TotalPaymentCash');
}

function ModalFeedCollected() {
  EventBus.$emit('ModalFeesCollectedShow');
}





</script>
